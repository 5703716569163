<template>
  <div  class="px-15px px-lg-25px">
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('guan-li-ge-ren-zi-liao') }}</h1>
        </div>
      </div>
    </div>



    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('ji-chu-xin-xi') }}</h5>
      </div>
      <div class="card-body">
          <div class="form-group row">
            <label class="col-md-2 col-form-label">{{ $t('ni-de-ming-zi') }}</label>
            <div class="col-md-10">
              <input type="text" class="form-control" :placeholder="$t('ni-de-ming-zi-0')" v-model="userData.FullName" >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-2 col-form-label">{{ $t('ni-de-shou-ji') }}</label>
            <div class="col-md-10">
              <input type="text" class="form-control" :placeholder="$t('ni-de-shou-ji-0')" v-model="userData.Phone">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">{{ $t('zhao-pian') }}</label>
            <div class="col-md-10">
              <div class="input-group" data-toggle="aizuploader" data-type="image">
                <div class="input-group-prepend">
                    <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                </div>
                <div class="form-control file-amount" @click="addFile(1)">{{ $t('xuan-ze-wen-jian') }}</div>
                <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
              </div>
              <div class="file-preview box sm" v-if="userData.Photo">
                <div class="d-flex justify-content-between align-items-center mt-2 file-preview-item" >
                  <div class="align-items-center align-self-stretch d-flex justify-content-center thumb">
                    <img :src="`${userData.Photo}`" class="img-fit">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">{{ $t('ni-de-mi-ma') }}</label>
            <div class="col-md-10">
              <input type="password" class="form-control" :placeholder="$t('xin-mi-ma')" v-model="userData.Password">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">{{ $t('que-ren-mi-ma-0') }}</label>
            <div class="col-md-10">
                <input type="password" class="form-control" :placeholder="$t('que-ren-mi-ma-0')" v-model="userData.PasswordConfirm">
            </div>
          </div>
        </div>
    </div>
    
    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('fu-kuan-she-zhi') }}</h5>
      </div>
      <div class="card-body">
        <div class="row" id="cash">
          <label class="col-md-3 col-form-label">{{ $t('xian-jin-zhi-fu') }}</label>
          <div class="col-md-9">
            <label class="aiz-switch aiz-switch-success mb-3">
              <input v-model="userData.IsCash" name="cash_on_delivery_status" type="checkbox" >
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="row" id="bank">
          <label class="col-md-3 col-form-label">{{ $t('yin-hang-zhi-fu') }}</label>
          <div class="col-md-9">
            <label class="aiz-switch aiz-switch-success mb-3">
              <input v-model="userData.IsBank"  name="bank_payment_status" type="checkbox" >
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 col-form-label" for="bank_name">{{ $t('yin-hang-ming-cheng') }}</label>
          <div class="col-md-9">
            <input type="text" v-model="userData.Bank"   class="form-control mb-3" :placeholder="$t('yin-hang-ming-cheng-0')" required>
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 col-form-label" for="bank_acc_name">{{ $t('yin-hang-zhang-hu') }}</label>
          <div class="col-md-9">
            <input type="text" v-model="userData.BankName"  class="form-control mb-3" :placeholder="$t('yin-hang-zhang-hu-0')" required>
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 col-form-label" for="bank_acc_no">{{ $t('yin-hang-zhang-hao') }}</label>
          <div class="col-md-9">
            <input type="text" v-model="userData.BankNumber"  class="form-control mb-3" :placeholder="$t('yin-hang-zhang-hao-0')" required>
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 col-form-label" for="bank_routing_no">{{ $t('yin-hang-lu-you-hao-ma') }}</label>
          <div class="col-md-9">
            <input type="number" v-model="userData.BankRoute"   class="form-control mb-3" :placeholder="$t('yin-hang-lu-you-hao-ma-0')" required>
          </div>
        </div>
        
        <div class="row"  id="usdt">
          <label class="col-md-3 col-form-label">{{ $t('usdt-zhi-fu') }}</label>
          <div class="col-md-9">
            <label class="aiz-switch aiz-switch-success mb-3">
              <input v-model="userData.IsUsdt"  name="usdt_payment_status" type="checkbox" >
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 col-form-label" for="usdt_type">{{ $t('usdt-wang-luo') }}</label>
          <div class="col-md-9">
              <input type="text" v-model="userData.UsdtNetwork" class="form-control mb-3" :placeholder="$t('usdt-wang-luo-0')">
          </div>
        </div>
        <div class="row">
          <label class="col-md-3 col-form-label" for="usdt_address">{{ $t('usdt-di-zhi') }}</label>
          <div class="col-md-9">
            <input type="text" v-model="userData.UsdtAddress"  class="form-control mb-3" :placeholder="$t('usdt-di-zhi-0')">
          </div>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 text-right">
      <button type="button" class="btn btn-primary" @click="updateUserInfo">{{ $t('geng-xin-ge-ren-xin-xi') }}</button>
    </div>
    

  </div>
</template>
<script>
import { Popup } from 'vant'
import pageBar from '@/components/page'
import previewBar from '@/components/fileList'
import { updateUser, userInfo, fileUpload } from '@/api/user'
import { userVerify, shopInfo } from '@/api/seller'
export default {
  components: {
    pageBar,
    previewBar,
    Popup
  },
  data() {
    return {
      userData: {},
      verForm: {
        CardType: '',
        CardFront: '',
        CardBack: '',
      },
      uploadObj: {
        Photo: '',
        CardFront: '',
        CardBack: '',
      },
      typeList: [
        {
          value: '1',
          label: this.$t('shen-fen-zheng-0')
        },
        {
          value: '2',
          label: this.$t('hu-zhao-0')
        },
        {
          value: '3',
          label: this.$t('jia-shi-zheng')
        },
      ]
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    shopInfo() {
      return this.$store.state.shopInfo
    },
  },
  watch: {
    userInfo(val) {
      if (val.ID) {
        this.userData = Object.assign({
          Password: '',
          PasswordConfirm: ''
        }, this.userInfo)
      }
    },
    shopInfo(val) {
      if (val.ID) {
        this.verForm = {
          CardType: val.CardType + '',
          CardFront: val.CardFront,
          CardBack: val.CardBack,
        }
      }
    }
  },
  mounted() {
    if (this.userInfo.ID) {
      this.userData = Object.assign({
        Password: '',
        PasswordConfirm: ''
      }, this.userInfo)
    }
    if (this.shopInfo.ID) {
        this.verForm = {
          CardType: this.shopInfo.CardType + '',
          CardFront: this.shopInfo.CardFront,
          CardBack: this.shopInfo.CardBack,
        }
      }
  },
  methods: {
    init() {
      this.form = Object.assign({}, this.shopData)
      if (this.form.Logo) {
        this.fileList = [
          {
            url: this.fomr.Logo
          }
        ]
      }
      if (this.form.Banner) {
        this.fileList2 = this.form.Banner.split(',').map(v => {
          return {
            url: v
          }
        })
      }
    },
    initUser() {
      userInfo().then(res => {
        if (res.code == 0) {
          this.$store.state.userInfo = res.data
        }
      })
    },
    initShopInfo() {
      shopInfo().then(res => {
        if (res.code == 0) {
          this.$store.state.shopInfo = res.data
        }
      })
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    uploadFile() {
      let file = document.getElementById('fileInput').files[0]
      let form = new FormData()
      form.append('file', file)
      fileUpload(form).then(res => {
        if (res.code == 0) {
          if (this.fileType == 1) {
            this.userData.Photo = "/upload/tmp/"+res.data
            this.uploadObj.Photo = res.data
          } else if (this.fileType == 2) {
            this.verForm.CardFront ="/upload/tmp/"+ res.data
            this.uploadObj.CardFront = res.data
          } else if (this.fileType == 3) {
            this.verForm.CardBack ="/upload/tmp/"+ res.data
            this.uploadObj.CardBack = res.data
          }
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    removeFile() {
      this.fileList = []
    },
    removeFile2(i) {
      this.fileList2.splice(i, 1)
    },
    updateUserInfo() {
      if (this.userData.Password && !this.userData.PasswordConfirm) {
        this.$toast(this.$t('qing-zai-ci-shu-ru-mi-ma'))
        return
      }
      let form = new FormData()
      form.append('FullName', this.userData.FullName)
      form.append('Phone', this.userData.Phone)
      if(this.uploadObj.Photo.length > 0) {
        form.append('Photo', this.uploadObj.Photo)
      }
      form.append('Password', this.userData.Password)
      form.append('PasswordConfirm', this.userData.PasswordConfirm)
      form.append('IsCash', this.userData.IsCash ? 1: 0)
      form.append('IsBank', this.userData.IsBank ? 1: 0)
      form.append('BankName', this.userData.BankName)
      form.append('BankNumber', this.userData.BankNumber)
      form.append('Bank', this.userData.Bank)
      form.append('BankRoute', this.userData.BankRoute)
      form.append('IsUsdt', this.userData.IsUsdt ? 1: 0)
      form.append('UsdtAddress', this.userData.UsdtAddress)
      form.append('UsdtAddress', this.userData.UsdtAddress)
      updateUser(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('geng-xin-cheng-gong'))
          this.initUser()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    doVerify() {
      if (!this.verForm.CardType) {
        this.$toast(this.$t('qing-xuan-ze-zheng-jian-lei-xing'))
        return
      }
      if (!this.verForm.CardFront) {
        this.$toast(this.$t('qing-shang-chuan-zheng-jian-zheng-mian-zhao'))
        return
      }
      if (!this.verForm.CardBack) {
        this.$toast(this.$t('qing-shang-chuan-zheng-jian-fan-mian-zhao'))
        return
      }
      let form = new FormData()
      form.append('CardType', this.verForm.CardType)
      if(this.uploadObj.CardFront.length > 0) {
        form.append('CardFront', this.uploadObj.CardFront)
      }
      if(this.uploadObj.CardBack.length > 0) {
        form.append('CardBack', this.uploadObj.CardBack)
      }
      userVerify(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('geng-xin-cheng-gong'))
          this.initShopInfo()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>